import React from "react";
import HomePage from "../../features/Home/Home";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  
  return (
    <>
    <ToastContainer
    position="top-left"
    autoClose={1000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    draggable
    />
    <div className="App">
      <div className="homePage line">
          <h1>8bitMints</h1>
          <p>easily convert images into 8bit style</p>
      </div>
      <HomePage />
      <div className="content">
      </div>
    </div>
    </>
  );
}

export default App;
