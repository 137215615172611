import React, { useState } from "react";
import { imageGetter } from "./Image";
import axios from "axios";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { canvasSave } from "./saveCanvas";
import { download } from "./downloadImage";


export default function HomePage(){
    //image sliders
    const [whValues, setwhValues] = useState(100)
    const [pxValues, setpxValues] = useState(10)
    const [imageUpload, setImageUpload] = useState(false)
    const [imageSelect, setImageSelect] = useState(false)

    function handleNumChange(e) {
        const numValue = e.target.value
        setwhValues(numValue)
    }

    function handlePXChange(e) {
        const numValue = e.target.value
        setpxValues(numValue)
    }

    function resetAll(){
        setwhValues(100)
        setpxValues(10)
        setImageUpload(false)
        setImageSelect(false)
    }

    const uploadImage = () => {
        console.log(imageUpload)
        const formData = new FormData()
        formData.append("file", imageUpload)
        formData.append("upload_preset", "upbit29383")
        axios.post("https://api.cloudinary.com/v1_1/dfvcq2b/upload", 
        formData).then((response)=> {
            setImageSelect(response.data.secure_url)
            toast.success("Image uploaded")
        });
    };

    
    const newImg = imageGetter(whValues, whValues, pxValues, imageSelect)
    
    const saveNew = () => {
        setImageUpload(canvasSave())
        // setImageUpload(canvasSave().files)
        // uploadImage()
    }

    return(
        <div className="homePage">
            <div>
                {imageUpload === false &&
                <>
                    <label className="ui button inverted" htmlFor="file-upload">Select Image</label>             
                    <input id="file-upload" type="file" 
                    onChange={(event) => {
                        setImageUpload(event.target.files[0])
                    }}/>
                </> 
                }
                {imageUpload !== false &&
                <>
                    {imageSelect === false &&
                    <>
                        <p>Image selected. Click "pixelate" to continue.</p>
                        <Button inverted onClick={uploadImage}>Pixelate</Button>  
                    </>
                    }
                    {imageSelect !== false &&
                    <>
                    <Button inverted onClick={resetAll}>Reset All</Button>  
                    <div>
                        <h1>Size: {whValues}</h1>
                        <input type="range" 
                        min="100"
                        max="500"
                        defaultValue={100}
                        value={whValues} onChange={(e) => handleNumChange(e)}/>
                    </div>
                    <div>
                        <h1>Detail: {pxValues}</h1>
                        <input type="range" 
                        min="1"
                        max="25"
                        defaultValue={5}
                        value={pxValues} onChange={(e) => handlePXChange(e)}/>
                    </div>
                    <canvas id="mycanvas"></canvas>  
                    <p></p> 
                    {/* <Button inverted onClick={saveNew}>Save to Cloud</Button>  */}
                    <Button inverted onClick={download}>Download</Button> 
                    </>
                    }
                </>
                }
            </div>
        </div>
        
    )
}