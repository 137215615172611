var eightBit = require('8bit'); // only needed for NPM installs
 
export function imageGetter(width, height, px, imgUrl){
    var img2 = new Image(width, height);
    img2.crossOrigin = "Anonymous";
    img2.onload = function() {
      eightBit(document.getElementById('mycanvas'), img2, px);
    };
    img2.src = imgUrl;

    return (
        img2
    )
}


